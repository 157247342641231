<template>
  <div class="grade">
    <div class="grade-mine">{{ info.name_en }}（{{ info.name_oth }}）</div>
    <div class="grade-body">
      <div class="grade-body-title">{{ $t("等级权益") }}</div>
      <div class="grade-body-equity">
        <div
          class="grade-body-list"
          v-for="(item, index) in info.power_smeta.power"
          :key="item"
        >
          {{ index + 1 + "、" + info.power[item].name }}
        </div>
      </div>
      <div class="grade-body-title" style="margin-top:5vw">{{ $t("积分说明") }}</div>
      <div
        class="grade-body-code"
        v-for="item in info.cost"
        :key="item.name"
      >
      <div>{{item.name}}</div>
      <div class="grade-body-point" :style="{background:item.css=='danger'?'red':'green'}">{{item.css=='danger'?'-'+item.points:'+'+item.points}}</div>
      </div>
    </div>
    <div v-if="info.enable" class="grade-btn" @click="show = true">
      {{ $t("立即购买") }}
    </div>
    <van-action-sheet
      v-model="show"
      :actions="info.amount_smeta"
      @select="gradeBuy"
    />
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "GradeDetail",
  data() {
    return {
      show: false,
      grade: "", // 等级Id
      info: "", // 信息
      key: "",
    };
  },
  mounted() {
    this.grade = this.$route.query.grade;
    this.gradeDetail();
  },
  methods: {
    gradeDetail() {
      // 等级详情
      this.$http
        .post("/v1/gradeDetail", {
          grade: this.grade,
        })
        .then((res) => {
          if (res.code == 200) {
            this.info = res.data.info;
          }
        });
    },
    gradeBuy(val) {
      this.$http
        .post("/v1/gradeBuy", {
          grade: this.grade,
          key: val.key,
        })
        .then((res) => {
          if (res.code == 200) {
            Toast(this.$t("购买成功"));
          }
        });
    },
  },
};
</script>

<style scoped>
.grade {
  min-height: 100vh;
  background: #f5f6fa;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.grade-mine {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  width: 100vw;
  background: #333;
  color: white;
}
.grade-body {
  padding: 5vw;
}
.van-card {
  box-shadow: 0 0 8px 0 #ccc;
  margin-bottom: 5vw;
  border-radius: 5px;
  /* background: white; */
}
.grade-btn {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: white;
  border-radius: 3px;
  background: #5d74f2;
}
.grade-body-list {
  padding: 5px 10px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  width: fit-content;
  margin-top: 10px;
  margin-right: 10px;
  flex-shrink: 0;
}
.grade-body-title{
  color: #333;
  font-size: 15px;
  font-weight: bold;
  margin-top: 5px;
}
.grade-body-equity {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.grade-body-code{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.grade-body-point{
  width: 20px;
  line-height: 20px;
  text-align: center;
  width: 60px;
  color: white;
  border-radius:50px ;
}

</style>